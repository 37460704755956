import React, { FC } from 'react'

import AddressAutocompleteField from 'pages/_serverRendered/CmsPage/AddressAutocompleteField'

import classnames from 'classnames'
import styles from './styles.module.scss'

interface Props {
  className?: string
  id?: string
  title?: string
  description?: string
  variant?: string
  onRequestHeaderHeight?: () => number | undefined
}

const GetStarted: FC<Props> = ({ className, id, title, description, variant, onRequestHeaderHeight }) => {
  return (
    <div className={classnames(styles.getStarted, className)} id={id} data-hide-on="logged-in">
      <div>
        <h3 className={styles.title}>
          {title != null ? (
            title
          ) : (
            <>
              {/* eslint-disable-next-line react/jsx-no-literals */}
              {'Get your Realm'}&nbsp;{'Score'}
            </>
          )}
        </h3>
        <p className={styles.description}>
          {description != null ? description : 'We currently cover most standalone, single family homes.'}
        </p>
      </div>
      <AddressAutocompleteField
        className={styles.field}
        variant={variant}
        positionLabel="getStarted"
        onRequestHeaderHeight={onRequestHeaderHeight}
        placeholder="Enter an address to get started"
        scrollToFocus={!!onRequestHeaderHeight}
      />
    </div>
  )
}

export default GetStarted
