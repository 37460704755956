import FullAddressInput, { Prediction } from 'components/FullAddressInput'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { track } from 'utils/analytics'
import cx from 'classnames'

import styles from './styles.module.scss'

export type { Prediction }

interface AddressAutocompleteProps {
  className?: string
  onPredictionSelected?: (queryParams: Record<string, string>) => void
  placeholder?: string | Array<string>
  positionLabel: string
  renderSubmitButton?: (onClick: (e: any) => void, disabled: boolean) => React.ReactNode
  renderPredictionDescription?: (prediction: Prediction) => React.ReactNode
  renderIcon?: () => React.ReactNode
  variant?: string
  readOnly?: boolean
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, isMobile: boolean) => void
}

const AddressAutocomplete: FC<AddressAutocompleteProps> = ({
  className,
  onPredictionSelected,
  placeholder,
  positionLabel,
  renderSubmitButton,
  renderPredictionDescription,
  renderIcon,
  variant,
  readOnly,
  onFocus,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [isMobile, setIsMobile] = useState(false)

  const handlePredictionSelected = useCallback(
    async (prediction: Prediction) => {
      if (!prediction) return
      const queryParams = {
        description: prediction.description,
        place_id: prediction.placeId,
      }
      if (new URLSearchParams(window.location.search).has('mc')) {
        // Forward on marketing campaign id.
        queryParams['mc'] = new URLSearchParams(window.location.search).get('mc')
      }
      if (variant != null) {
        queryParams['variant'] = variant
      } else if (window.location.pathname == '/l/giveaway') {
        queryParams['variant'] = 'sign_up'
      } else if (window.location.pathname !== '/l/project_max' && queryParams['variant'] !== 'compare') {
        queryParams['variant'] = 'onboarding-2-0'
      }
      // For now, force all valid onboarding flows that have a property, through
      // camv1
      queryParams['flow'] = 'camv1'

      const trackingOpts: { variant: string; flow?: string } = { variant: queryParams['variant'] }
      if (queryParams['flow']) trackingOpts.flow = queryParams['flow']
      await track('address selected', trackingOpts)
      if (onPredictionSelected) {
        onPredictionSelected(queryParams)
      } else {
        const queryString = new URLSearchParams(queryParams).toString()
        window.location.href = `/google_places/search?${queryString}`
      }
    },
    [onPredictionSelected, variant]
  )

  const handleFocus = useCallback(
    (event) => {
      if (onFocus) onFocus(event, isMobile)
    },
    [onFocus, isMobile]
  )

  const isDone = useRef<boolean>(false)
  useEffect(() => {
    if (inputValue.length > 0 && !isDone.current) {
      isDone.current = true
      track('address start typing', { position: positionLabel })
    }
  }, [inputValue, positionLabel])

  useEffect(() => {
    if (!window) return

    function handleResize() {
      setIsMobile(window.innerWidth < 640)
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      if (!window) return

      window.removeEventListener('resize', handleResize)
    }
  }, [])

  let finalPlaceholder = 'Enter an address to get started'
  if (placeholder) {
    if (typeof placeholder === 'string') {
      finalPlaceholder = placeholder
    } else if (placeholder instanceof Array) {
      if (placeholder.length > 1 && isMobile) finalPlaceholder = placeholder[1]
      else if (placeholder.length > 0) finalPlaceholder = placeholder[0]
    }
  }

  return (
    <FullAddressInput
      className={cx(styles.search, className)}
      onPredictionSelected={handlePredictionSelected}
      value={inputValue}
      onChange={setInputValue}
      placeholder={finalPlaceholder}
      renderSubmitButton={renderSubmitButton}
      readOnly={readOnly}
      renderDescription={renderPredictionDescription}
      renderIcon={renderIcon}
      onFocus={handleFocus}
    />
  )
}

export default AddressAutocomplete
