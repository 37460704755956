import React, { FC } from 'react'
import { PdfEmbedSlice } from 'pages/_serverRendered/Blog/types'

interface PdfEmbedProps {
  slice: PdfEmbedSlice
}

const PdfEmbed: FC<PdfEmbedProps> = ({ slice }) => {
  if (slice.primary.file.link_type !== 'Media' || slice.primary.file.kind !== 'document') return null

  return (
    <div className="tw-aspect-w-7 tw-aspect-h-6">
      <iframe title={slice.primary.file.name} src={slice.primary.file.url} className="tw-absolute tw-inset-0" />
    </div>
  )
}

export default PdfEmbed
