import CtaButton from 'pages/_serverRendered/Blog/shared/CtaButton'
import CustomEmbed from 'pages/_serverRendered/Blog/shared/CustomEmbed'
import GetStartedSlice from 'pages/_serverRendered/Blog/shared/GetStarted'
import ImageCarousel from 'pages/_serverRendered/Blog/shared/ImageCarousel'
import PdfEmbed from 'pages/_serverRendered/Blog/shared/PdfEmbed'
import { BlogPostData } from 'pages/_serverRendered/Blog/types'
import { RichText } from 'prismic-reactjs'
import React, { FC } from 'react'

import styles from '../styles.module.scss'

interface BlogContentProps {
  data: BlogPostData
}

const BlogContent: FC<BlogContentProps> = ({ data }) => {
  return (
    <div className={styles.blogContent}>
      {data.body1.map((e, i) =>
        e.slice_type === 'rich_text' ? (
          <div key={i} className="tw-max-w-3xl tw-my-8 tw-mx-auto tw-leading-8 tw-text-lg tw-space-y-8">
            <RichText render={e.primary.text} />
          </div>
        ) : e.slice_type == 'pdf_embed' ? (
          <div key={i} className="tw-max-w-3xl tw-my-8 tw-mx-auto tw-leading-8 tw-text-lg tw-space-y-8">
            <PdfEmbed slice={e} />
          </div>
        ) : e.slice_type == 'custom_embed' ? (
          <div key={i} className="tw-max-w-3xl tw-my-8 tw-mx-auto tw-leading-8 tw-text-lg tw-space-y-8">
            <CustomEmbed slice={e} />
          </div>
        ) : e.slice_type == 'image_carousel' ? (
          <div key={i} className="tw-max-w-3xl tw-my-8 tw-mx-auto tw-leading-8 tw-text-lg tw-space-y-8">
            <ImageCarousel slice={e} />
          </div>
        ) : e.slice_type == 'cta_button' ? (
          <div key={i} className="tw-max-w-3xl tw-my-8 tw-mx-auto tw-leading-8 tw-text-lg tw-space-y-8">
            <CtaButton slice={e} />
          </div>
        ) : e.slice_type == 'get_started' ? (
          <div key={i} className="tw-bg-moss-light tw-py-4">
            <div className="tw-max-w-3xl tw-my-8 tw-mx-auto tw-leading-8 tw-text-lg tw-space-y-8">
              <GetStartedSlice slice={e} />
            </div>
          </div>
        ) : null
      )}
    </div>
  )
}

export default BlogContent
