import React, { FC } from 'react'
import { FooterContainer, Hr, SocialLinks } from 'components/MarketingAndPropertyPages/Footer'
import HomepageLinks from 'components/MarketingAndPropertyPages/Footer/HomepageLinks'

const Footer: FC = () => (
  <FooterContainer>
    <Hr />
    <HomepageLinks />
    <SocialLinks />
  </FooterContainer>
)

export default Footer
