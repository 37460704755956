import React, { FC } from 'react'
import {
  blogPostPath,
  blogPostsByAuthorPath,
  blogPostsByCategoryPath,
  blogPostsByTagPath,
  formatDate,
} from 'pages/_serverRendered/Blog/utils'

import { PrismicBlogPostResult } from 'pages/_serverRendered/Blog/types'

interface BlogFooterProps {
  blogPost: PrismicBlogPostResult
}

const BlogFooter: FC<BlogFooterProps> = ({ blogPost }) => {
  return (
    <footer className="tw-mx-6 tw-space-x-8 tw-text-gray-600">
      <a className="hover:tw-text-gray-600" href={blogPostsByAuthorPath(blogPost.data.author)}>
        {blogPost.data.author}
      </a>
      <a className="hover:tw-text-gray-600" href={blogPostPath(blogPost)}>
        {formatDate(blogPost.data.date)}
      </a>
      {blogPost.data.category1?.data && (
        <a className="hover:tw-text-gray-600" href={blogPostsByCategoryPath(blogPost.data.category)}>
          {blogPost.data.category1.data.display_name}
        </a>
      )}
      <span>
        {blogPost.tags.map((e, i) => (
          <span key={e}>
            <a className="hover:tw-text-gray-600" href={blogPostsByTagPath(e)}>
              {e}
            </a>
            {i < blogPost.tags.length - 1 && <span>{', '}</span>}
          </span>
        ))}
      </span>
    </footer>
  )
}

export default BlogFooter
