import { useEffect, FC } from 'react'

import { trackPage } from 'utils/analyticsV2'

interface Props {
  name?: string
  onLoadComplete?: () => void
}
const PageLoad: FC<Props> = ({ name, onLoadComplete }) => {
  useEffect(() => {
    let cancel = false
    const load = async () => {
      let pageName = name
      pageName ||= document.body.dataset.pageName
      pageName ||= document.location.pathname.replace(/^[/]/, '').replace(/[_/]/g, '-')
      await trackPage(pageName)
      if (cancel) return
      if (onLoadComplete) onLoadComplete()
    }
    load()
    return () => {
      cancel = true
    }
  }, [name, onLoadComplete])

  return null
}

export default PageLoad
