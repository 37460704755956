import { FacebookShareButton, TwitterShareButton } from 'react-share'
import React, { FC } from 'react'

import FacebookIcon from 'svgs/social/facebook'
import TwitterIcon from 'svgs/social/twitter'

interface ShareThisProps {
  className?: string
  url: string
}

const ShareThis: FC<ShareThisProps> = ({ className, url }) => {
  return (
    <div className={className}>
      <div>{'Share this:'}</div>
      <div className="tw-flex tw-gap-2 tw-mt-2">
        <TwitterShareButton url={url}>
          <div className="tw-flex tw-items-center tw-gap-1 tw-py-1 tw-px-3 tw-text-sm tw-bg-gray-200 tw-border tw-border-gray-300 tw-rounded tw-text-gray-800 hover:tw-border-gray-400 tw-font-light tw-shadow-sm">
            <TwitterIcon className="tw-w-4 tw-h-4 tw-text-gray-600 tw-stroke-current" />
            {'Twitter'}
          </div>
        </TwitterShareButton>
        <FacebookShareButton url={url}>
          <div className="tw-flex tw-items-center tw-gap-1 tw-py-1 tw-px-3 tw-text-sm tw-bg-gray-200 tw-border tw-border-gray-300 tw-rounded tw-text-gray-800 hover:tw-border-gray-400 tw-font-light tw-shadow-sm">
            <FacebookIcon className="tw-w-4 tw-h-4 tw-text-gray-600 tw-stroke-current" />
            {'Facebook'}
          </div>
        </FacebookShareButton>
      </div>
    </div>
  )
}

export default ShareThis
