import RealmLogo, { RealmLogoSmall } from 'components/MarketingSite/Header/RealmLogo'
import React, { FC } from 'react'
import QRCode from 'react-qr-code'

import styles from './HomepageLinks.module.scss'

const HomepageLinks: FC = () => (
  <div className={styles.container}>
    <div className={styles.brand}>
      <div>
        <RealmLogoSmall className={styles.logo} />
        <RealmLogo className={styles.logoDesktop} />
      </div>
      <h3>{'Get the most out of your biggest asset: your home'}</h3>
    </div>
    <div className={styles.links}>
      <div className={styles.linkGroup}>
        <a href="/how-it-works">{'How It Works'}</a>
        <a href="/why-realm">{'Why Realm'}</a>
        <a href="/resources/library" target="_blank" rel="noreferrer">
          {'Resources'}
        </a>
      </div>
      <div className={styles.linkGroup}>
        <a href="/about-us">{'About Realm'}</a>
        <a href="/vendor-signup">{'Join Our Vendor Network'}</a>
        <a href="/about-us/careers">{'Careers'}</a>
      </div>
      <div className={styles.linkGroup}>
        <h5>{'Download The App'}</h5>
        <p>{'Get project price estimates, track your home value, and see neighborhood insights'}</p>

        <a
          className={styles.callToAction}
          href={
            'https://app.adjust.com/qr4wgfa?redirect_macos=https://apps.apple.com/us/app/realm-home/id1589251076&redirect_windows=https://apps.apple.com/us/app/realm-home/id1589251076'
          }
        >
          {'Download The App'}
        </a>
        <div className={styles.qrCode}>
          <QRCode
            value={`https://app.adjust.com/qr4wgfa?redirect_macos=https://apps.apple.com/us/app/realm-home/id1589251076&redirect_windows=https://apps.apple.com/us/app/realm-home/id1589251076`}
          />
        </div>
      </div>
    </div>
  </div>
)

export default HomepageLinks
