import { PrismicBlogPostResult } from 'pages/_serverRendered/Blog/types'

export const formatDate = (date: string): string => {
  return new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric', day: 'numeric', timeZone: 'UTC' }).format(
    new Date(date)
  )
}

export const blogPostPath = (blogPost: PrismicBlogPostResult): string => {
  return `/resources/library/${blogPost.uid}`
}

export const blogPostsByAuthorPath = (author: string): string => {
  return `/resources/library/author/${author}`
}

export const blogPostsByCategoryPath = (category: string): string => {
  return `/resources/library/category/${category}`
}

export const blogPostsByTagPath = (tag: string): string => {
  return `/resources/library/tag/${tag}`
}

export const requestMoreBlogPosts = async (
  params: Record<string, string>
): Promise<{ results: PrismicBlogPostResult[]; has_more: boolean } | null> => {
  const qs = new URLSearchParams(params).toString()
  const response = await fetch(`/resources/library?${qs}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
  if (response.ok) {
    const json = await response.json()
    return json
  } else {
    return null
  }
}
