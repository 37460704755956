import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { GetStartedSlice } from 'pages/_serverRendered/Blog/types'
import FullAddressInput, { Prediction } from 'components/FullAddressInput'
import { hasTextPresent } from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'
import { track } from 'utils/analytics'

import styles from '../styles.module.scss'

interface GetStartedProps {
  slice: GetStartedSlice
}

const GetStarted: FC<GetStartedProps> = ({ slice }) => {
  const [inputValue, setInputValue] = useState<string>('')

  const handlePredictionSelected = useCallback((prediction: Prediction | null) => {
    if (!prediction) return
    const queryParams = {
      place_id: prediction.placeId,
    }
    if (new URLSearchParams(window.location.search).has('mc')) {
      // Forward on marketing campaign id.
      queryParams['mc'] = new URLSearchParams(window.location.search).get('mc')
    }
    // No variant param for this component to check first.
    if (window.location.pathname == '/l/giveaway') {
      queryParams['variant'] = 'sign_up'
    } else if (window.location.pathname !== '/l/project_max' && queryParams['variant'] !== 'compare') {
      queryParams['variant'] = 'onboarding-2-0'
    }
    const queryString = new URLSearchParams(queryParams).toString()
    window.location.href = `/google_places/search?${queryString}`
  }, [])

  const isDone = useRef<boolean>(false)
  useEffect(() => {
    if (inputValue.length > 0 && !isDone.current) {
      isDone.current = true
      track('address start typing', { position: 'get-started' })
    }
  }, [inputValue])

  return (
    <div className={styles.getStartedSlice} data-hide-on="logged-in">
      <div>
        {hasTextPresent(slice.primary.title1) && <h3>{slice.primary.title1[0].text}</h3>}
        {hasTextPresent(slice.primary.text) && <p>{slice.primary.text[0].text}</p>}
      </div>
      <FullAddressInput
        className="tw-mx-6 tw-mt-4 tw-relative"
        onChange={setInputValue}
        onPredictionSelected={handlePredictionSelected}
        placeholder={slice.primary.placeholder}
        value={inputValue}
      />
    </div>
  )
}

export default GetStarted
