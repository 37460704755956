import React, { FC, PropsWithChildren } from 'react'

import styles from './styles.module.scss'

export const FooterContainer: FC<PropsWithChildren> = ({ children }) => (
  <footer className={styles.footer}>{children}</footer>
)

export const Hr: FC = () => <hr className={styles.hr} />

export const SocialLinks: FC = () => (
  <div className={styles.container}>
    <ul className={styles.terms}>
      <li className={styles.copyright}>
        {`© ${new Date().getFullYear()} Realm`}
        <br />
        {'All rights reserved'}
      </li>
      <li>
        <a href="/privacy">{'Privacy Policy'}</a>
      </li>
      <li>
        <a href="/terms">{'Terms'}</a>
      </li>
    </ul>
    <ul className={styles.social}>
      <li>
        <a href="https://www.instagram.com/realm/" target="_blank" rel="noreferrer">
          {'Instagram'}
        </a>
      </li>
      <li>
        <a href="https://twitter.com/realmhome" target="_blank" rel="noreferrer">
          {'Twitter'}
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/realmhome" target="_blank" rel="noreferrer">
          {'Facebook'}
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/company/realmhome/" target="_blank" rel="noreferrer">
          {'LinkedIn'}
        </a>
      </li>
    </ul>
  </div>
)
