import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import cx from 'classnames'

import AddressAutocomplete from 'components/MarketingSite/Header/AddressAutocomplete'

import styles from './styles.module.scss'

interface AddressAutocompleteFieldProps {
  className?: string
  containerClassName?: string
  onInputVisibleChange?: (boolean) => void
  buttonLabelLong?: string
  buttonLabelShort?: string
  showButton?: boolean
  onPredictionSelected?: (queryParams: Record<string, string>) => void
  variant?: string
  placeholder?: string
  positionLabel: string
  readOnly?: boolean
  onRequestHeaderHeight?: () => number | undefined
  scrollToFocus?: boolean
}

export const AddressAutocompleteField: FC<AddressAutocompleteFieldProps> = ({
  className,
  containerClassName,
  onInputVisibleChange,
  buttonLabelLong,
  buttonLabelShort,
  showButton,
  onPredictionSelected,
  readOnly,
  variant,
  placeholder,
  positionLabel,
  onRequestHeaderHeight,
  scrollToFocus,
}) => {
  const [scrollCheck, setScrollCheck] = useState<HTMLInputElement | null>(null)
  const renderSubmitButton = useCallback(
    (onClick: (e: any) => void, disabled: boolean) => (
      <button
        type="button"
        className={cx(styles.button, 'searchBarButtonExtraCSS')}
        disabled={disabled}
        onClick={onClick}
      >
        <span className={styles.labelLong}>{buttonLabelLong ?? 'Get my property analysis'}</span>
        <span className={styles.labelShort}>{buttonLabelShort ?? 'Get started'}</span>
      </button>
    ),
    [buttonLabelShort, buttonLabelLong]
  )

  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (onInputVisibleChange != null) {
      if ('IntersectionObserver' in window) {
        const observer = new IntersectionObserver((entries) =>
          entries.forEach((entry) => {
            onInputVisibleChange(entry.isIntersecting)
          })
        )
        if (ref.current) observer.observe(ref.current)

        return () => observer?.disconnect()
      } else {
        onInputVisibleChange(true)
      }
    }
  }, [onInputVisibleChange])

  const handleFocus = useCallback((event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, isMobile) => {
    if (!isMobile) return
    setScrollCheck(event.target as HTMLInputElement)
  }, [])

  useEffect(() => {
    if (!scrollCheck) return
    let cancel = false
    let lastPosition: number | null = null
    let lastHeight: number | null = null
    let lastCheck = false
    let request
    const scrollCheckFn = () => {
      if (cancel) return
      if (lastPosition != window.pageYOffset || lastHeight != window.innerHeight) {
        lastPosition = window.pageYOffset
        lastHeight = window.innerHeight
        lastCheck = false
        // We're not done scrolling/resizing, so continue checking.
        request = setTimeout(scrollCheckFn, 100)
      } else if (!lastCheck) {
        lastCheck = true
        // We appear to be done, but give one last grace period.
        request = setTimeout(scrollCheckFn, 500)
      } else {
        // We can now scroll the user to where we want them.
        const headerHeight = onRequestHeaderHeight ? onRequestHeaderHeight() || 0 : 0
        const extra = -4 // DIRTY HACK
        const scrollTo = extra + scrollCheck.getBoundingClientRect().top - headerHeight + window.pageYOffset
        window.scrollTo(0, scrollTo)
        setScrollCheck(null)
      }
    }
    request = setTimeout(scrollCheckFn, 100)
    return () => {
      cancel = true
      clearTimeout(request)
    }
  }, [scrollCheck, onRequestHeaderHeight])

  return (
    <div className={cx(styles.addressAutoCompleteField, containerClassName)} ref={ref}>
      <AddressAutocomplete
        className={cx(styles.search, className)}
        onPredictionSelected={onPredictionSelected}
        placeholder={placeholder || 'Enter an address'}
        positionLabel={positionLabel}
        renderSubmitButton={showButton ? renderSubmitButton : undefined}
        variant={variant}
        readOnly={readOnly}
        onFocus={scrollToFocus ? handleFocus : undefined}
      />
    </div>
  )
}

export default AddressAutocompleteField
