import React, { FC } from 'react'

import { CtaButtonSlice } from 'pages/_serverRendered/Blog/types'

interface CtaButtonProps {
  slice: CtaButtonSlice
}

const CtaButton: FC<CtaButtonProps> = ({ slice }) => {
  return (
    <div className="tw-flex">
      <a
        className="tw-inline-block tw-mx-auto tw-bg-moss-bright tw-py-2 tw-px-6 tw-rounded-4xl tw-font-bold tw-text-gray-900 hover:tw-text-gray-900 hover:tw-no-underline"
        href={slice.primary.cta_url.url}
        target={slice.primary.cta_url.target}
      >
        {slice.primary.cta_text}
      </a>
    </div>
  )
}

export default CtaButton
