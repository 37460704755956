import React, { FC } from 'react'

import Carousel from 'react-slick'
import { ImageCarouselSlice } from 'pages/_serverRendered/Blog/types'

interface ImageCarouselProps {
  slice: ImageCarouselSlice
}

const IMG_WIDTH = 770

const ImageCarousel: FC<ImageCarouselProps> = ({ slice }) => {
  return (
    <Carousel dots arrows={false} draggable={false}>
      {slice.items.map((e) => (
        <div key={e.image.url}>
          {e.image.url && (
            <img
              src={`${e.image.url}&w=${IMG_WIDTH}`}
              srcSet={`${e.image.url}&w=${IMG_WIDTH}&dpr=1 1x, ${e.image.url}&w=${IMG_WIDTH}&dpr=2 2x`}
              alt={e.image.alt || ''}
              width={e.image.dimensions?.width}
              height={e.image.dimensions?.height}
            />
          )}
        </div>
      ))}
    </Carousel>
  )
}

export default ImageCarousel
