import React, { FC } from 'react'

import { CustomEmbedSlice } from 'pages/_serverRendered/Blog/types'

interface CustomEmbedProps {
  slice: CustomEmbedSlice
}

const CustomEmbed: FC<CustomEmbedProps> = ({ slice }) => {
  return (
    <div
      className="tw-overflow-x-scroll"
      dangerouslySetInnerHTML={{ __html: slice.primary.html_code.map((e) => e.text).join(' ') }}
    />
  )
}

export default CustomEmbed
